@import url('./assets/fonts/Manrope/Manrope.stylesheet.css');

:root * {
  font-family: Manrope, sans-serif;
  letter-spacing: 1,
}

body {
  margin: 0;
  font-family: Manrope, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Manrope, sans-serif;
}
